import { BusinessUnit } from '@/infrastructure/config/businessUnit';
import { SellerService } from '@/services/sellers/types';
import { RouteConfig } from 'vue-router';

export const getGoldpartnerRoutes = (): RouteConfig[] => [
    {
        path: '',
        name: 'goldpartner',
        props: true,
        component: () => import('@/pages/Dashboard.vue'),
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: '/goldpartner/ventas',
                name: 'goldpartner/sales',
                meta: {
                    requiresAuth: true
                },
                component: () => import(/* webpackChunkName: "GenericSection" */ '@/pages/EmptyPage.vue')
            },
            {
                path: '/goldpartner/productos',
                name: 'goldpartner/product',
                meta: {
                    requiresAuth: true
                },
                component: () => import(/* webpackChunkName: "GenericSection" */ '@/pages/EmptyPage.vue')
            },
            {
                path: 'goldpartner/sync-results',
                alias: '/phw/goldpartner/sync-results',
                name: 'goldpartner/sync-results',
                meta: {
                    requiresAuth: true,
                    requiresOneOfServices: [SellerService.STOCKHUB],
                    requiresBusinessUnit: [BusinessUnit.PROMOFARMA]
                },
                component: () => import(/* webpackChunkName: "SyncResults" */ '@/pages/marketplace/syncResults/SyncResults.vue')
            },
            {
                path: 'goldpartner/offer-upload',
                alias: '/phw/goldpartner/offer-upload',
                name: 'goldpartner/offer-upload',
                meta: {
                    requiresAuth: true,
                    requiresBusinessUnit: [BusinessUnit.PROMOFARMA]
                },
                component: () => import(/* webpackChunkName: "OfferUpload" */ '@/pages/marketplace/offerUpload/OfferUpload.vue')
            },
            {
                path: '/goldpartner/catalogos',
                name: 'goldpartner/catalog',
                meta: {
                    requiresAuth: true
                },
                component: () => import(/* webpackChunkName: "GenericSection" */ '@/pages/EmptyPage.vue')
            },
            {
                path: '/goldpartner/tienda',
                name: 'goldpartner/myshop',
                meta: {
                    requiresAuth: true
                },
                component: () => import(/* webpackChunkName: "GenericSection" */ '@/pages/EmptyPage.vue')
            },
            {
                path: '/goldpartner/cajas',
                name: 'goldpartner/boxes',
                meta: {
                    requiresAuth: true
                },
                component: () => import(/* webpackChunkName: "GenericSection" */ '@/pages/EmptyPage.vue')
            }
        ]
    }
];
