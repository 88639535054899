import storage from '@/storage';
import { Route, NavigationGuardNext } from 'vue-router';
import { loadLanguageAsync } from '@/infrastructure/plugins/i18n';
import { LOCALE, DOCMORRIS_LOCALES, ZURROSE_LOCALES, PROMOFARMA_LOCALES } from '@/infrastructure/locales';
import { isDocMorris, isPromofarma, isZurrose } from '@/infrastructure/config/businessUnit';
import { StorageKey } from '@/storage/keys';

export default async (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {
    const preferredLanguage: any = storage.get(StorageKey.LOCALE);

    if (!preferredLanguage) {
        next();

        return;
    }

    if (isDocMorris()) {
        await loadPreferredLanguage(DOCMORRIS_LOCALES, preferredLanguage);
    }

    if (isZurrose()) {
        await loadPreferredLanguage(ZURROSE_LOCALES, preferredLanguage);
    }

    if (isPromofarma()) {
        await loadPreferredLanguage(PROMOFARMA_LOCALES, preferredLanguage);
    }

    next();
};

const loadPreferredLanguage = async (availableLocales: LOCALE[], storageLocale: any): Promise<void> => {
    if (availableLocales.includes(storageLocale)) {
        await loadLanguageAsync(storageLocale);
    }
};
