import { Route } from 'vue-router';
import GetViewedTours from '@/services/tours/GetViewedTours';
import { checkIfOfferUploadPageTourIsAbleToStart } from '@/tours/offerUploadPage';
import { checkIfOrderCardTourIsAbleToStart } from '@/tours/orderCard';
import { SellerService } from '@/services/sellers/types';
import store from '@/store';
import router from '@/router';

export const loadTours = async (to: Route) => {
    try {
        const userActiveServices: SellerService[] = store().getters['auth/getActiveServices'];
        const viewedTours = GetViewedTours().execute();

        checkIfOfferUploadPageTourIsAbleToStart(to, userActiveServices, viewedTours);
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
    }

    try {
        const viewedTours = GetViewedTours().execute();
        checkIfOrderCardTourIsAbleToStart(router, viewedTours);
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
    }
};
