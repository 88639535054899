import { getBusinessUnit } from '@/infrastructure/config/getBusinessUnit';

export enum BusinessUnit {
    PROMOFARMA = 'promofarma',
    DOCMORRIS = 'docmorris',
    ZURROSE = 'zurrose'
}

export const isDocMorris = (): boolean => getBusinessUnit() === BusinessUnit.DOCMORRIS;
export const isZurrose = (): boolean => getBusinessUnit() === BusinessUnit.ZURROSE;
export const isPromofarma = (): boolean => getBusinessUnit() === BusinessUnit.PROMOFARMA;
