import { VueGtag } from 'vue-gtag';
import { AppliesTo, AnalyticParamValueNotDefinedOrEmpty } from '@/services/analytics/types';
import { SearchCriteria } from '@/services/analytics/searches/types';

export class SearchEventLogger {
    private analyticsLogger: VueGtag;

    constructor(analyticsLogger: VueGtag) {
        this.analyticsLogger = analyticsLogger;
    }

    searched(
        appliesTo: AppliesTo,
        searchCriteria: SearchCriteria | AnalyticParamValueNotDefinedOrEmpty,
        searchTerm: string | AnalyticParamValueNotDefinedOrEmpty
    ): void {
        this.analyticsLogger.event('search', {
            applies_to: appliesTo,
            criteria: searchCriteria,
            search_term: searchTerm
        });
    }

    searchedResults(
        appliesTo: AppliesTo,
        searchCriteria: SearchCriteria | AnalyticParamValueNotDefinedOrEmpty,
        searchTerm: string | AnalyticParamValueNotDefinedOrEmpty,
        hasResults: boolean
    ): void {
        this.analyticsLogger.event('search_results', {
            applies_to: appliesTo,
            criteria: searchCriteria,
            search_term: searchTerm,
            has_results: hasResults
        });
    }
}
