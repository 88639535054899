import { VueGtag } from 'vue-gtag';
import { LoginMethod } from '@/services/analytics/auth/types';

export class AuthEventLogger {
    private analyticsLogger: VueGtag;

    constructor(analyticsLogger: VueGtag) {
        this.analyticsLogger = analyticsLogger;
    }

    login(loginMethod: LoginMethod = LoginMethod.EMAIL_AND_PASSWORD): void {
        this.analyticsLogger.event('login', {
            method: loginMethod
        });
    }
}
