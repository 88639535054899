import Shepherd from 'shepherd.js';
import VueRouter from 'vue-router';
import { isPromofarma } from '@/infrastructure/config/businessUnit';
import { initTour, TourData, addProgressIndicator } from '@/infrastructure/plugins/shepherd';
import getImagePublicPath from '@/infrastructure/utils/getImagePublicPath';
import { i18n } from '@/infrastructure/plugins/i18n';
import MarkAsViewedTour from '@/services/tours/MarkAsViewedTour';
import { ViewedTour } from '@/services/tours/types';
import { waitFor } from '@/utils/waitFor';

/**
 *
 * Custom images for this tour are located on: "images/tours/orderCard"
 */

const tourData: TourData = {
    name: 'orderCard',
    expirationDate: new Date('2024-05-08T22:00:00.000Z')
};

const getTourSteps = (tour: Shepherd.Tour): Shepherd.Step.StepOptions[] => {
    return [
        {
            title: `${i18n.t('tour.order_card-step_one-title')}`,
            text: `<div class="columns p-1" style="font-size: 14px;">
                        <div class="column">
                            <p>${i18n.t('tour.order_card-step_one-description_01', ['<strong>', '</strong>'])}</p>
                        </div>
                    </div>
                    <div class="columns p-1">
                        <div class="column">
                            <img alt="${i18n.t('accessibility.product_information')}" src="${getImagePublicPath(
                'images/tours/orderCard/step_01.svg'
            )}"/>
                        </div>
                    </div>`,
            arrow: false,
            classes: 'ph-tour-wide-step-modal',
            buttons: [
                {
                    text: `${i18n.t('tour.next_step_button')}`,
                    action: tour.next
                }
            ],
            when: {
                show() {
                    addProgressIndicator(tour);
                }
            }
        },
        {
            title: `${i18n.t('tour.order_card-step_two-title')}`,
            text: `<div class="columns p-1" style="font-size: 14px;">
                        <div class="column">
                             <p>${i18n.t('tour.order_card-step_two-description_01', ['<strong>', '</strong>'])}</p>
                             <p>${i18n.t('tour.order_card-step_two-description_02', ['<strong>', '</strong>'])}</p>
                        </div>
                    </div>
                    <div class="columns p-1">
                        <div class="column">
                            <img alt="${i18n.t('accessibility.price_and_units')}" src="${getImagePublicPath(
                'images/tours/orderCard/step_02.svg'
            )}"/>
                        </div>
                    </div>`,
            arrow: false,
            classes: 'ph-tour-wide-step-modal',
            buttons: [
                {
                    text: `${i18n.t('tour.back_step_button')}`,
                    classes: 'shepherd-button-secondary',
                    action: tour.back
                },
                {
                    text: `${i18n.t('tour.next_step_button')}`,
                    action: tour.next
                }
            ],
            when: {
                show() {
                    addProgressIndicator(tour);
                }
            }
        },
        {
            title: `${i18n.t('tour.order_card-step_three-title')}`,
            text: `<div class="columns p-1" style="font-size: 14px;">
                        <div class="column">
                            <div style="position: absolute; top: 84px;">
                                <p class="mb-3">${i18n.t('tour.order_card-step_three-description_01')}:</p>
                                <ul style="list-style: disc; line-height: 1.5; margin-left: 20px">
                                    <li>${i18n.t('customer_information')}</li>
                                    <li>${i18n.t('customer_billing_address')}</li>
                                    <li>${i18n.t('order_summary')}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="columns mt-5 p-1 pt-3">
                        <div class="column">
                            <img alt="${i18n.t('accessibility.more_order_information_modal')}" src="${getImagePublicPath(
                'images/tours/orderCard/step_03.svg'
            )}"/>
                        </div>
                    </div>`,
            arrow: false,
            classes: 'ph-tour-wide-step-modal',
            buttons: [
                {
                    text: `${i18n.t('tour.back_step_button')}`,
                    classes: 'shepherd-button-secondary',
                    action: tour.back
                },
                {
                    text: `${i18n.t('tour.next_step_button')}`,
                    action: tour.next
                }
            ],
            when: {
                show() {
                    addProgressIndicator(tour);
                }
            }
        },
        {
            title: `${i18n.t('tour.more_features_coming_soon_title')}`,
            text: `<div class="columns p-1" style="font-size: 14px;">
                        <div class="column">
                            <p>${i18n.t('tour.order_card-step_four-description_01', [
                                '<strong>',
                                '</strong>',
                                i18n.t('order_status_managed')
                            ])}</p>
                        </div>
                    </div>
                    <div class="columns p-1">
                        <div class="column">
                            <img alt="${i18n.t('accessibility.products_cancelled')}" src="${getImagePublicPath(
                'images/tours/orderCard/step_04.svg'
            )}"/>
                        </div>
                    </div>
            `,
            arrow: false,
            classes: 'ph-tour-wide-step-modal',
            buttons: [
                {
                    text: `${i18n.t('tour.back_step_button')}`,
                    classes: 'shepherd-button-secondary',
                    action: tour.back
                },
                {
                    text: `${i18n.t('tour.complete_tour_button')}`,
                    action: tour.complete
                }
            ],
            when: {
                show() {
                    addProgressIndicator(tour);
                }
            }
        }
    ];
};

const startTour = (): void => {
    const tour = initTour({ tourName: tourData.name });

    tour.addSteps(getTourSteps(tour));

    tour.start();
};

const checkIfOrderCardTourIsAbleToStart = async (router: VueRouter, viewedTours: ViewedTour[]): Promise<void> => {
    try {
        if (isPromofarma()) {
            return;
        }

        const isAlreadyViewed = viewedTours.some((viewedTour) => viewedTour.name === tourData.name);

        if (isAlreadyViewed) {
            return;
        }

        const now = new Date();
        if (tourData.expirationDate !== null && now > tourData.expirationDate) {
            return;
        }

        const timeToPacifyTheUI = 300;
        await waitFor(timeToPacifyTheUI);

        if (router.currentRoute.name !== 'marketplace/sales') {
            return;
        }

        startTour();
        MarkAsViewedTour().execute(tourData);
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
    }
};

export { checkIfOrderCardTourIsAbleToStart };
