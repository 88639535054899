<template>
    <div class="CookiesConsentModalManageMySettings">
        <div class="is-flex mb-5">
            <div data-qa-ta="goBackArrow" class="is-clickable" @click="onGoBack">
                <b-icon icon="arrow-left" size="is-small" />
            </div>
            <p class="CookiesConsentModalManageMySettings-text has-text-weight-semibold ml-3">
                {{ $t('cookies_modal_manage_my_settings') }}
            </p>
        </div>
        <b-collapse
            class="CookiesConsentModalManageMySettings-collapsable"
            animation="slide"
            :open.sync="isCollapsableOpen"
            aria-id="contentIdForA11y1"
        >
            <template #trigger="props">
                <div class="is-flex is-align-items-center py-4" role="button" aria-controls="contentIdForA11y3" @click.stop="handleClick">
                    <b-switch v-model="isGoogleAnalyticsAccepted" data-qa-ta="googleAnalyticsOption" type="is-primary" size="is-small" />
                    <p>Google Analytics</p>
                    <b-icon class="ml-auto" :class="props.open && 'icon-close'" icon="chevron-down" />
                </div>
            </template>
            <div class="CookiesConsentModalManageMySettings-collapsable-content has-background-white-ter p-5">
                <p data-qa-ta="googleAnalyticsOptionInformation">
                    {{ $t('cookies_modal_manage_google_analytics') }}
                </p>
            </div>
        </b-collapse>
        <b-button
            class="is-capitalized is-block ml-auto mt-6"
            type="is-primary"
            data-qa-ta="acceptCookiesSettings"
            @click="$emit('accept-settings', { isGoogleAnalyticsAccepted })"
        >
            {{ $t('accept') }}
        </b-button>
    </div>
</template>

<script>
import { ref } from 'vue';

export default {
    name: 'CookiesConsentModalManageMySettings',
    setup(props, { emit }) {
        const isGoogleAnalyticsAccepted = ref(true);
        const isCollapsableOpen = ref(false);

        const onGoBack = () => {
            isCollapsableOpen.value = false;
            emit('slide-modal-content');
        };

        const handleClick = (event) => {
            if (!event.target.className.includes('check')) {
                isCollapsableOpen.value = !isCollapsableOpen.value;
            }
        };

        return {
            isGoogleAnalyticsAccepted,
            isCollapsableOpen,
            onGoBack,
            handleClick
        };
    }
};
</script>

<style lang="scss">
.CookiesConsentModalManageMySettings {
    &-text {
        font-size: $ph-font-size-medium;
    }

    &-collapsable {
        border-top: 1px solid $border;
        border-bottom: 1px solid $border;
    }
    .icon-close {
        transform: rotate(180deg);
    }
}
</style>
