import { AxiosInstance } from 'axios';
import axiosInstance from '@/infrastructure/fetcher/axios';
import { UserToken } from '@/services/users/authentication/types';

export class SessionDetails {
    private fetcher: AxiosInstance;

    constructor(fetcher: AxiosInstance) {
        this.fetcher = fetcher;
    }

    async execute(): Promise<UserToken> {
        const result = await this.fetcher.post('/session-details');

        return result && this.fromGqlToUserToken(result.data);
    }

    private fromGqlToUserToken(gqlData: Record<string, any>): UserToken {
        return {
            sellerId: gqlData.sellerId
        };
    }
}

export default () => new SessionDetails(axiosInstance);
