import { VueConstructor } from 'vue';
import VueGtag from 'vue-gtag';
import { serverData } from '@/infrastructure/config/serverData';
import router from '@/router';

export const initGoogleAnalytics = (Vue: VueConstructor): void => {
    Vue.use(
        VueGtag,
        {
            config: {
                id: serverData.googleAnalyticsId,
                params: {
                    business_unit: serverData.businessUnit
                }
            },
            enabled: false
        },
        router
    );
};
