import Shepherd from 'shepherd.js';
import { Route } from 'vue-router';
import router from '@/router';
import { isZurrose, isPromofarma } from '@/infrastructure/config/businessUnit';
import getImagePublicPath from '@/infrastructure/utils/getImagePublicPath';
import { initTour, TourData, addProgressIndicator } from '@/infrastructure/plugins/shepherd';
import { i18n } from '@/infrastructure/plugins/i18n';
import AnalyticsEventLogger from '@/services/analytics/AnalyticsEventLogger';
import { TourName } from '@/services/analytics/tours/types';
import MarkAsViewedTour from '@/services/tours/MarkAsViewedTour';
import { SellerService } from '@/services/sellers/types';
import { ViewedTour } from '@/services/tours/types';
import { waitFor } from '@/utils/waitFor';
import store from '@/store';
import { LOCALE } from '@/infrastructure/locales';
import { offset } from '@floating-ui/vue';

/**
 *
 * Custom images for this tour are located on: "images/tours/offerUploadPage"
 */

const tourData: TourData = {
    name: 'offerUploadPage',
    expirationDate: new Date('2024-04-26T22:00:00.000Z')
};

const analyticsEventTourName: TourName = TourName.OFFER_UPLOAD_LAUNCH;

const getTourSteps = (tour: Shepherd.Tour): Shepherd.Step.StepOptions[] => {
    return [
        {
            title: `${i18n.t('tour.offer_upload_visualisation_title')}`,
            text: `<div class="columns m-0 px-3">
                        <div class="column pl-5 py-4" style="justify-content: end; display: flex; flex-direction: column">
                            <p class="mb-4" style="text-align: center; font-size: 22px; line-height: normal">
                                <span style="color: #e30312; font-weight: bold;">${i18n.t('before')}</span>
                            </p>
                            <div>
                                <img alt="${i18n.t('accessibility.preview_image_alt', [
                                    `${i18n.t('before')} ${i18n.t('tour.offer_upload_visualisation_title')}`
                                ])}" style="border: 5px solid #e30312" src="${getImagePublicPath(
                `images/tours/offerUploadPage/bo_view_${getFileNameByLocale()}.svg`
            )}"/>
                                <div style="background-color: #000; height: 97%; position: relative; top: -99%; opacity: 0.5; width: 98%; left: 5px"></div>
                            </div>
                        </div>
                        <span style="color: #00d264; position: absolute; top: 56%; left: 44%; font-size: 150px">&#x2B62;</span>
                        <div class="column pl-5 py-4" style="justify-content: end; display: flex; flex-direction: column">
                            <p class="mb-4" style="text-align: center; font-size: 22px; line-height: normal">
                                <span style="color: #00d264; font-weight: bold;">${i18n.t('now')}:</span>
                                ${i18n.t('tour.offer_upload_visualisation_description_01')}<br/>
                                <span style="color: #00d264; font-weight: bold;">${i18n.t(
                                    'tour.offer_upload_visualisation_description_02'
                                )}!</span>
                            </p>
                            <div>
                                <img alt="${i18n.t('accessibility.preview_image_alt', [
                                    `${i18n.t('now')} ${i18n.t('tour.offer_upload_visualisation_title')}`
                                ])}" style="border: 5px solid #00d264" src="${getImagePublicPath(
                `images/tours/offerUploadPage/ph_view_${getFileNameByLocale()}.svg`
            )}"/>
                            </div>
                        </div>
                    </div>`,
            arrow: false,
            classes: 'ph-tour-wide-step-modal ph-tour-restore-spacing-on-body',
            buttons: [
                {
                    text: `${i18n.t('tour.next_step_button')}`,
                    action: tour.next
                }
            ],
            beforeShowPromise: async (): Promise<void> => {
                const userActiveServices: SellerService[] = store().getters['auth/getActiveServices'];
                const isGoldPartner = userActiveServices.includes(SellerService.GOLD_PARTNER);

                const targetRoute = { name: 'marketplace/offer-upload' };
                if (isGoldPartner && isPromofarma()) {
                    targetRoute.name = 'goldpartner/offer-upload';
                }

                await router.push(targetRoute).catch(() => {}); /* We consciously decided to ignore this error */

                const estimatedTimeForMenuRenderingToComplete = 200; /* This is an arbitrary value, but it has been calculated using the throttling options */
                await waitFor(estimatedTimeForMenuRenderingToComplete);
                return;
            },
            when: {
                show() {
                    addProgressIndicator(tour);
                }
            }
        },
        {
            title: `${i18n.t('tour.download_template_title')}`,
            text: `${i18n.t('tour.download_template_description')}`,
            attachTo: {
                element: '[data-tour-selector="downloadTemplate"]',
                on: 'bottom'
            },
            scrollTo: {
                behavior: 'smooth',
                block: 'center'
            },
            classes: 'mt-5',
            buttons: [
                {
                    text: `${i18n.t('tour.back_step_button')}`,
                    classes: 'shepherd-button-secondary',
                    action: tour.back
                },
                {
                    text: `${i18n.t('tour.next_step_button')}`,
                    action: tour.next
                }
            ],
            canClickTarget: false,
            when: {
                show() {
                    addProgressIndicator(tour);
                }
            }
        },
        {
            title: `${i18n.t('tour.upload_your_offers_title')}`,
            text: `${i18n.t('tour.upload_your_offers_description')}`,
            attachTo: {
                element: '[data-tour-selector="uploadOffer"]',
                on: 'bottom'
            },
            scrollTo: {
                behavior: 'smooth',
                block: 'center'
            },
            classes: 'mt-5',
            buttons: [
                {
                    text: `${i18n.t('tour.back_step_button')}`,
                    classes: 'shepherd-button-secondary',
                    action: tour.back
                },
                {
                    text: `${i18n.t('tour.next_step_button')}`,
                    action: tour.next
                }
            ],
            canClickTarget: false,
            when: {
                show() {
                    addProgressIndicator(tour);
                }
            }
        },
        {
            title: `${i18n.t('tour.review_the_results_title')}`,
            text: `<div class="columns m-0 py-4">
            <div class="column is-half pl-5 py-4">
                <p class="mb-4">
                    <span style="color: #00d264; font-weight: bold; font-size: 20px">${i18n.t(
                        'tour.review_the_results_description_01'
                    )}</span>
                </p>
                <ul style="list-style: disc; line-height: 2.5; margin-left: 50px">
                    <li>${i18n.t('tour.review_the_results_description_02')}</li>
                    <li>${i18n.t('tour.review_the_results_description_03')}</li>
                    <li>${i18n.t('tour.review_the_results_description_04')}</li>
                </ul>
            </div>
            <div class="columns pl-5 py-4" style="justify-content: center; display: flex; flex-direction: column">
                    <img alt="${i18n.t('accessibility.preview_image_alt', [
                        `${i18n.t('review_the_results')}`
                    ])}" style="max-width: 80%; box-shadow: 0px 0px 10px 0px rgba(181,181,181,0.79)" src="${getImagePublicPath(
                `images/tours/offerUploadPage/ph_view_${getFileNameByLocale()}.svg`
            )}"/>
            </div>
        </div>`,
            attachTo: {
                element: '[data-tour-selector="reviewTheResults"]',
                on: 'bottom'
            },
            scrollTo: {
                behavior: 'smooth',
                block: 'center'
            },
            classes: 'ph-tour-wide-step-modal ph-tour-restore-spacing-on-body mt-5',
            buttons: [
                {
                    text: `${i18n.t('tour.back_step_button')}`,
                    classes: 'shepherd-button-secondary',
                    action: tour.back
                },
                {
                    text: `${i18n.t('tour.next_step_button')}`,
                    action: tour.next
                }
            ],
            canClickTarget: false,
            when: {
                show() {
                    addProgressIndicator(tour);
                }
            }
        },
        {
            title: `${i18n.t('tour.view_history_title')}`,
            text: `<div class="columns is-multiline m-0 px-3">
            <div class="columns p-5">
                <span class="is-small" style="border: 1px solid #002924; padding: 7px 7px; border-radius: 10px; color: #002924; margin-right: 10px; max-height: 32px">
                    <div style="display: flex">
                        <i class="mdi mdi-filter-variant pr-2"></i>
                        ${i18n.t('historic')}
                    </div>
                </span>
                <p class="mb-4">
                    ${i18n.t('tour.view_history_description')}
                </p>
            </div>
            <div class="columns p-5" style="justify-content: end; display: flex; flex-direction: column">
                    <img alt="${i18n.t('accessibility.preview_image_alt', [
                        `${i18n.t('tour.view_history_title')}`
                    ])}" style="max-width: 80%; margin: 0 auto" src="${getImagePublicPath(
                `images/tours/offerUploadPage/hist_${getFileNameByLocale()}.svg`
            )}"/>
            </div>
        </div>`,
            attachTo: {
                element: '[data-qa-ta="openHistoricModal"]',
                on: 'left'
            },
            scrollTo: {
                behavior: 'smooth',
                block: 'center'
            },
            classes: 'ph-tour-wide-step-modal',
            buttons: [
                {
                    text: `${i18n.t('tour.back_step_button')}`,
                    classes: 'shepherd-button-secondary',
                    action: tour.back
                },
                {
                    text: `${i18n.t('tour.complete_tour_button')}`,
                    action: tour.next
                }
            ],
            canClickTarget: false,
            floatingUIOptions: { middleware: [offset(25)] },
            when: {
                show() {
                    addProgressIndicator(tour);
                }
            }
        }
    ];
};

const getFileNameByLocale = (): string => {
    const fileNameByLocale: Record<LOCALE, string> = {
        [LOCALE.es_ES]: 'es',
        [LOCALE.pt_PT]: 'pt',
        [LOCALE.fr_FR]: 'fr',
        [LOCALE.it_IT]: 'it',
        [LOCALE.de_DE]: 'de',
        [LOCALE.fr_CH]: 'fr',
        [LOCALE.de_CH]: 'de',
        [LOCALE.en_GB]: 'en'
    };
    return fileNameByLocale[i18n.locale];
};

const addEventListeners = (tour: Shepherd.Tour): void => {
    tour.once('start', () => {
        AnalyticsEventLogger().tourStarted(analyticsEventTourName);
    });

    tour.once('cancel', () => {
        AnalyticsEventLogger().tourSkipped(analyticsEventTourName);
    });

    tour.once('complete', () => {
        AnalyticsEventLogger().tourCompleted(analyticsEventTourName);
    });
};

const startTour = (): void => {
    const tour = initTour({ tourName: tourData.name });

    tour.addSteps(getTourSteps(tour));

    addEventListeners(tour);

    tour.start();
};

const checkIfOfferUploadPageTourIsAbleToStart = async (
    to: Route,
    userActiveServices: string[],
    viewedTours: ViewedTour[]
): Promise<void> => {
    try {
        if (isZurrose()) {
            return;
        }

        const isAlreadyViewed = viewedTours.some((viewedTour) => viewedTour.name === tourData.name);

        if (isAlreadyViewed) {
            return;
        }

        const now = new Date();
        if (tourData.expirationDate !== null && now > tourData.expirationDate) {
            return;
        }

        const isPrivateRoute = to.meta?.requiresAuth === true;
        if (!isPrivateRoute) {
            return;
        }

        const requiresOneOfServices = [SellerService.STOCKHUB];
        const matchBetweenRequiredServicesAndActiveServices = requiresOneOfServices.some((requiredService) =>
            userActiveServices.includes(requiredService)
        );
        if (!matchBetweenRequiredServicesAndActiveServices) {
            return;
        }

        startTour();
        MarkAsViewedTour().execute(tourData);
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
    }
};

export { checkIfOfferUploadPageTourIsAbleToStart };
