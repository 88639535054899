import { RouteConfig } from 'vue-router';
import { SellerService } from '@/services/sellers/types';
import { BusinessUnit } from '@/infrastructure/config/businessUnit';

export const getMarketplaceRoutes = (): RouteConfig[] => [
    {
        path: '',
        name: 'marketplace',
        props: true,
        component: () => import('@/pages/Dashboard.vue'),
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: 'marketplace/sales',
                alias: '/phw/marketplace/sales',
                name: 'marketplace/sales',
                meta: {
                    requiresAuth: true,
                    requiresOneOfServices: [SellerService.SAME_DAY, SellerService.MARKETPLACE]
                },
                component: () => import(/* webpackChunkName: "Sales" */ '@/pages/marketplace/sales/Sales.vue')
            },
            {
                path: 'marketplace/sync-results',
                alias: '/phw/marketplace/sync-results',
                name: 'marketplace/sync-results',
                meta: {
                    requiresAuth: true,
                    requiresOneOfServices: [SellerService.STOCKHUB],
                    requiresBusinessUnit: [BusinessUnit.DOCMORRIS, BusinessUnit.PROMOFARMA]
                },
                component: () => import(/* webpackChunkName: "SyncResults" */ '@/pages/marketplace/syncResults/SyncResults.vue')
            },
            {
                path: 'marketplace/offer-upload',
                alias: '/phw/marketplace/offer-upload',
                name: 'marketplace/offer-upload',
                meta: {
                    requiresAuth: true,
                    requiresBusinessUnit: [BusinessUnit.DOCMORRIS, BusinessUnit.PROMOFARMA]
                },
                component: () => import(/* webpackChunkName: "OfferUpload" */ '@/pages/marketplace/offerUpload/OfferUpload.vue')
            },
            {
                path: 'marketplace/my-products',
                alias: '/phw/marketplace/my-products',
                name: 'marketplace/my-products',
                meta: {
                    requiresAuth: true
                },
                component: () => import(/* webpackChunkName: "MyProducts" */ '@/pages/marketplace/myProducts/MyProducts.vue')
            },
            {
                path: '/marketplace/productos',
                name: 'product',
                meta: {
                    requiresAuth: true
                },
                component: () => import(/* webpackChunkName: "GenericSection" */ '@/pages/EmptyPage.vue')
            },
            {
                path: '/marketplace/catalogos',
                name: 'marketplace/catalog',
                meta: {
                    requiresAuth: true
                },
                component: () => import(/* webpackChunkName: "GenericSection" */ '@/pages/EmptyPage.vue')
            },
            {
                path: '/marketplace/tienda',
                name: 'marketplace/myshop',
                meta: {
                    requiresAuth: true
                },
                component: () => import(/* webpackChunkName: "GenericSection" */ '@/pages/EmptyPage.vue')
            },
            {
                path: '/marketplace/cajas',
                name: 'marketplace/boxes',
                meta: {
                    requiresAuth: true
                },
                component: () => import(/* webpackChunkName: "GenericSection" */ '@/pages/EmptyPage.vue')
            }
        ]
    }
];
