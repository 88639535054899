<template>
    <div class="is-flex is-flex-direction-column">
        <i18n tag="p" path="cookies_modal_we_use_cookies">
            <span role="img" aria-label="cookie">🍪</span>
            <span
                class="has-text-primary has-text-weight-semibold is-clickable"
                role="button"
                tabindex="0"
                data-qa-ta="refuseAllCookies"
                @click="$emit('reject-all-cookies')"
                @keypress.space.prevent="$emit('reject-all-cookies')"
            >
                {{ $t('not_agree') }}
            </span>
            <a class="has-text-primary has-text-weight-semibold" data-qa-ta="viewLegalDocs" href="/phw/partner-privacy-policy">
                {{ $t('privacy_policy') }}
            </a>
        </i18n>
        <div class="is-flex is-justify-content-flex-end mt-6">
            <b-button data-qa-ta="manageCookiesSettings" @click="$emit('slide-modal-content')">
                {{ $t('cookies_modal_manage_my_settings') }}
            </b-button>
            <b-button type="is-primary" data-qa-ta="acceptAllCookies" class="ml-4" @click="$emit('accept-all-cookies')">
                {{ $t('cookies_modal_accept_all') }}
            </b-button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CookiesConsentModalAcceptAll'
};
</script>
