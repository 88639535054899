import { datadogLogs } from '@datadog/browser-logs';
import { serverData } from '@/infrastructure/config/serverData';
import { getDatadogConfiguration } from '@/infrastructure/config/datadog/getDatadogConfiguration';

const datadogConfiguration = getDatadogConfiguration(serverData.businessUnit);

if (serverData.isProd) {
    datadogLogs.init({
        clientToken: datadogConfiguration.clientToken,
        site: 'datadoghq.eu',
        datacenter: 'eu',
        service: 'partner-home-web',
        forwardErrorsToLogs: true,
        sessionSampleRate: 100
    });
}
