import axiosInstance from '../../../infrastructure/fetcher/axios';
import { AxiosInstance } from 'axios';

export class SignOut {
    private fetcher: AxiosInstance;

    constructor(fetcher: AxiosInstance) {
        this.fetcher = fetcher;
    }

    async execute() {
        return this.fetcher.post('/signout');
    }
}

export default () => new SignOut(axiosInstance);
