<template>
    <ErrorBoundary>
        <div class="App">
            <div class="App-wrap">
                <router-view />
                <SnackbarNotification />
                <CookiesConsentModal />
            </div>
        </div>
    </ErrorBoundary>
</template>

<script>
import { i18n } from '@/infrastructure/plugins/i18n.ts';
import { RoutesV1 } from '@/infrastructure/config/routeServicesV1Mappings.ts';
import { redirectTo } from '@/infrastructure/utils/redirectTo.ts';
import ErrorBoundary from '@/components/SharedComponents/ErrorBoundary.vue';
import SnackbarNotification from '@/components/SharedComponents/SnackbarNotification.vue';
import CookiesConsentModal from '@/components/SharedComponents/CookiesConsentModal/CookiesConsentModal.vue';

export default {
    components: {
        ErrorBoundary,
        SnackbarNotification,
        CookiesConsentModal
    },
    created() {
        this.$globalBus.on('auth/redirect-login', this.onLogout.bind(this));
    },
    metaInfo() {
        return this.getMetaInfo();
    },
    methods: {
        // When a user is logged out, it is redirected to login page
        onLogout(logoutMetadata) {
            try {
                redirectTo(RoutesV1.LOGOUT, () => {
                    this.$router
                        .push({
                            name: 'login',
                            params: { ...logoutMetadata }
                        })
                        .catch(() => {});
                });
            } catch (error) {
                this.$globalBus.emit('error', { error });
            }
        },
        getMetaInfo() {
            const metaData = [{ name: 'description', content: i18n.t('manage_your_marketplace_strategy') }];

            return {
                meta: [...metaData]
            };
        }
    }
};
</script>

<style lang="scss">
@import '~@/styles/_global.scss';

html,
body {
    height: 100%;
    color: $black-bis;
}

.App {
    display: flex;

    .App-wrap {
        flex: 1 1 auto;
        backface-visibility: hidden;
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        max-width: 100%;
        position: relative;
    }
}
</style>
