import storage from '@/storage';
import store from '@/store';
import { Route, NavigationGuardNext } from 'vue-router';
import { loadLanguageAsync } from '@/infrastructure/plugins/i18n';
import { StorageKey } from '@/storage/keys';

export default async (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {
    const sellerLocale = store().getters['auth/getUser'].seller.locale;

    if (sellerLocale) {
        await loadLanguageAsync(sellerLocale);

        try {
            storage.set(StorageKey.LOCALE, sellerLocale);
        } catch {
            // We consciously decided to ignore this error
        }
    }

    next();
};
