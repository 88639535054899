import Vuex, { Store } from 'vuex';
import { VueConstructor } from 'vue';
import app from './shared';
import auth from './auth';
import { RootState } from './types';

export const setupVuex = (Vue: VueConstructor<Vue>): void => {
    Vue.use(Vuex);
};

const storeInstance = (): (() => Store<RootState>) => {
    let store: Store<RootState>;

    return (): Store<RootState> => {
        if (store) {
            return store;
        }
        store = new Vuex.Store({
            modules: { app, auth }
        });

        return store;
    };
};

export default storeInstance();
