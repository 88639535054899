import { AxiosInstance } from 'axios';
import axios from '@/infrastructure/fetcher/axios';
import { Seller } from './types';

export class GetSeller {
    private fetcher: AxiosInstance;

    constructor(fetcher: AxiosInstance) {
        this.fetcher = fetcher;
    }

    async execute(sellerId: string): Promise<Seller> {
        const result = await this.fetcher.get(`/seller/${sellerId}`);

        return result.data;
    }
}

export default () => new GetSeller(axios);
