import { Route, NavigationGuardNext } from 'vue-router';
import { BusinessUnit } from '@/infrastructure/config/businessUnit';
import { serverData } from '@/infrastructure/config/serverData';

export default async (to: Route, from: Route, next: NavigationGuardNext) => {
    const requiresOneOfBusinessUnit: BusinessUnit[] | undefined = to?.meta?.requiresBusinessUnit;

    if (!requiresOneOfBusinessUnit || requiresOneOfBusinessUnit.length === 0) {
        next();

        return;
    }

    const allowBusinessUnit = requiresOneOfBusinessUnit.some((requiredBusinessUnit) => requiredBusinessUnit === serverData.businessUnit);

    if (allowBusinessUnit) {
        next();

        return;
    }

    next({ path: serverData.routesPrefix, params: { ...to.params } });
};
