<template>
    <b-modal v-model="isActive" :can-cancel="false" class="CookiesConsentModal" has-modal-card>
        <div aria-describedby="cookiesModal" aria-labelledby="dialogCookiesTitle" class="modal-card" role="dialog">
            <header class="modal-card-head">
                <h3 id="dialogCookiesTitle" class="modal-card-title" data-qa-ta="cookiesModalTitle">
                    {{ $t('cookies_modal_title') }}
                </h3>
            </header>

            <div class="modal-card-body pt-5">
                <div :class="{ slideLeft: slideContent, slideRight: !slideContent }" class="columns">
                    <div class="column is-12">
                        <CookiesConsentModalAcceptAll
                            @accept-all-cookies="onAcceptAllCookies"
                            @reject-all-cookies="onRejectAllCookies"
                            @slide-modal-content="onSlideContent"
                        />
                    </div>
                    <div class="column is-12">
                        <CookiesConsentModalManageMySettings @accept-settings="onAcceptSettings" @slide-modal-content="onSlideContent" />
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
import { onMounted, onBeforeUnmount, ref } from 'vue';
import { $gtag } from '@/main.js';
import { globalBus } from '@/infrastructure/plugins/globalBus.ts';
import { serverData } from '@/infrastructure/config/serverData.ts';
import SetConsentGA from '@/services/shared/analytics/SetConsentGA.ts';
import { ConsentGA } from '@/services/shared/analytics/types.ts';
import CookiesConsentModalAcceptAll from '@/components/SharedComponents/CookiesConsentModal/CookiesConsentModalAcceptAll.vue';
import CookiesConsentModalManageMySettings from '@/components/SharedComponents/CookiesConsentModal/CookiesConsentModalManageMySettings.vue';

export default {
    name: 'CookiesConsentModal',
    components: {
        CookiesConsentModalAcceptAll,
        CookiesConsentModalManageMySettings
    },
    setup() {
        const isActive = ref(false);
        const slideContent = ref(false);

        const setModalActive = () => {
            const delay = 600;
            setTimeout(() => {
                isActive.value = true;
            }, delay);
        };

        const enableGoogleAnalytics = () => {
            if (serverData.isProd) {
                $gtag.optIn();
            }
        };

        const disableGoogleAnalytics = () => {
            $gtag.optOut();
        };

        const onSlideContent = () => {
            slideContent.value = !slideContent.value;
        };

        const onCloseModal = () => {
            isActive.value = false;
        };

        const onAcceptAllCookies = () => {
            enableGoogleAnalytics();
            SetConsentGA().execute(ConsentGA.ALLOWED);
            onCloseModal();
        };

        const onAcceptSettings = (config) => {
            if (config.isGoogleAnalyticsAccepted) {
                enableGoogleAnalytics();
                SetConsentGA().execute(ConsentGA.ALLOWED);
            } else {
                disableGoogleAnalytics();
                SetConsentGA().execute(ConsentGA.DENIED);
            }

            onCloseModal();
        };

        const onRejectAllCookies = () => {
            disableGoogleAnalytics();
            SetConsentGA().execute(ConsentGA.DENIED);
            onCloseModal();
        };

        onMounted(() => {
            globalBus.on('consent/ask-for-consent', setModalActive);
            globalBus.on('consent/enable-google-analytics', enableGoogleAnalytics);
            globalBus.on('consent/disable-google-analytics', disableGoogleAnalytics);
        });

        onBeforeUnmount(() => {
            globalBus.off('consent/ask-for-consent');
            globalBus.off('consent/enable-google-analytics');
            globalBus.off('consent/disable-google-analytics');
        });

        return {
            isActive,
            slideContent,
            onSlideContent,
            onAcceptAllCookies,
            onAcceptSettings,
            onRejectAllCookies
        };
    }
};
</script>

<style lang="scss">
$transition: all 0.5s ease-in-out;

.CookiesConsentModal {
    .modal-card {
        width: 1024px;

        .modal-card-body {
            overflow: hidden;
            border-bottom-left-radius: 6px;
            border-bottom-right-radius: 6px;
        }
    }
    .slideLeft {
        transition: $transition;
        transform: translateX(-1014px);
    }
    .slideRight {
        transition: $transition;
        transform: translateX(0);
    }
}
</style>
