export enum StorageKey {
    ORDERS_REVIEWED = 'ordersReviewed',
    VIEWED_TOURS = 'viewedTours',
    LOCALE = 'preferredLanguage',
    MY_PRODUCTS_BULK_DOWNLOAD = 'myProductsBulkDownload',
    INVOICES_BULK_DOWNLOAD = 'invoicesBulkDownload',
    SYNC_RESULTS_BULK_DOWNLOAD = 'syncResultsBulkDownload',
    OFFER_UPLOAD_BULK_DOWNLOAD = 'offerUploadBulkDownload',
    OFFER_UPLOAD_HISTORY_DOWNLOAD = 'offerUploadHistoryDownload'
}
