var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "is-flex is-flex-direction-column" },
    [
      _c(
        "i18n",
        { attrs: { tag: "p", path: "cookies_modal_we_use_cookies" } },
        [
          _c("span", { attrs: { role: "img", "aria-label": "cookie" } }, [
            _vm._v("🍪"),
          ]),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass:
                "has-text-primary has-text-weight-semibold is-clickable",
              attrs: {
                role: "button",
                tabindex: "0",
                "data-qa-ta": "refuseAllCookies",
              },
              on: {
                click: function ($event) {
                  return _vm.$emit("reject-all-cookies")
                },
                keypress: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "space", 32, $event.key, [
                      " ",
                      "Spacebar",
                    ])
                  )
                    return null
                  $event.preventDefault()
                  return _vm.$emit("reject-all-cookies")
                },
              },
            },
            [
              _vm._v(
                "\n            " + _vm._s(_vm.$t("not_agree")) + "\n        "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "has-text-primary has-text-weight-semibold",
              attrs: {
                "data-qa-ta": "viewLegalDocs",
                href: "/phw/partner-privacy-policy",
              },
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$t("privacy_policy")) +
                  "\n        "
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "is-flex is-justify-content-flex-end mt-6" },
        [
          _c(
            "b-button",
            {
              attrs: { "data-qa-ta": "manageCookiesSettings" },
              on: {
                click: function ($event) {
                  return _vm.$emit("slide-modal-content")
                },
              },
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$t("cookies_modal_manage_my_settings")) +
                  "\n        "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "b-button",
            {
              staticClass: "ml-4",
              attrs: { type: "is-primary", "data-qa-ta": "acceptAllCookies" },
              on: {
                click: function ($event) {
                  return _vm.$emit("accept-all-cookies")
                },
              },
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.$t("cookies_modal_accept_all")) +
                  "\n        "
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }