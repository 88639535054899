import { RouteConfig } from 'vue-router';
import { BusinessUnit } from '@/infrastructure/config/businessUnit';
import { SellerService } from '@/services/sellers/types';

export const getFulfillmentRoutes = (): RouteConfig[] => [
    {
        path: '',
        name: 'fulfillment',
        props: true,
        component: () => import('@/pages/Dashboard.vue'),
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: '/fulfillment/pedidos',
                name: 'fulfillment/orders',
                meta: {
                    requiresAuth: true
                },
                component: () => import(/* webpackChunkName: "GenericSection" */ '@/pages/EmptyPage.vue')
            },
            {
                path: '/fulfillment/catalogo',
                name: 'fulfillment/catalog',
                meta: {
                    requiresAuth: true
                },
                component: () => import(/* webpackChunkName: "GenericSection" */ '@/pages/EmptyPage.vue')
            },
            {
                path: '/fulfilment/price-updates',
                alias: '/phw/fulfilment/price-updates',
                name: 'fulfilment/price-updates',
                meta: {
                    requiresAuth: true,
                    requiresBusinessUnit: [BusinessUnit.PROMOFARMA],
                    requiresOneOfServices: [SellerService.FULFILMENT]
                },
                component: () => import(/* webpackChunkName: "PriceUpdates" */ '@/pages/fulfilment/priceUpdates/PriceUpdates.vue')
            }
        ]
    }
];
