var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      staticClass: "CookiesConsentModal",
      attrs: { "can-cancel": false, "has-modal-card": "" },
      model: {
        value: _vm.isActive,
        callback: function ($$v) {
          _vm.isActive = $$v
        },
        expression: "isActive",
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "modal-card",
          attrs: {
            "aria-describedby": "cookiesModal",
            "aria-labelledby": "dialogCookiesTitle",
            role: "dialog",
          },
        },
        [
          _c("header", { staticClass: "modal-card-head" }, [
            _c(
              "h3",
              {
                staticClass: "modal-card-title",
                attrs: {
                  id: "dialogCookiesTitle",
                  "data-qa-ta": "cookiesModalTitle",
                },
              },
              [
                _vm._v(
                  "\n                " +
                    _vm._s(_vm.$t("cookies_modal_title")) +
                    "\n            "
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "modal-card-body pt-5" }, [
            _c(
              "div",
              {
                staticClass: "columns",
                class: {
                  slideLeft: _vm.slideContent,
                  slideRight: !_vm.slideContent,
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "column is-12" },
                  [
                    _c("CookiesConsentModalAcceptAll", {
                      on: {
                        "accept-all-cookies": _vm.onAcceptAllCookies,
                        "reject-all-cookies": _vm.onRejectAllCookies,
                        "slide-modal-content": _vm.onSlideContent,
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "column is-12" },
                  [
                    _c("CookiesConsentModalManageMySettings", {
                      on: {
                        "accept-settings": _vm.onAcceptSettings,
                        "slide-modal-content": _vm.onSlideContent,
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }