import Vue from 'vue';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import {
    ConfigProgrammatic,
    Autocomplete,
    Button,
    Checkbox,
    Collapse,
    Dropdown,
    Field,
    Icon,
    Input,
    Loading,
    Menu,
    Modal,
    Pagination,
    Select,
    Switch,
    Tag,
    Table,
    Tabs,
    Taginput,
    Tooltip,
    Upload,
    Progress,
    Radio
} from 'buefy';
import '@/infrastructure/plugins/monitoring';
import '@/infrastructure/plugins/logger';
import { i18n } from '@/infrastructure/plugins/i18n';
import createStore, { setupVuex } from '@/store';
import App from './App.vue';
import router from '@/router';
import { serverData } from '@/infrastructure/config/serverData';
import setupBus from '@/infrastructure/plugins/globalBus';
import { initGoogleAnalytics } from '@/infrastructure/plugins/GoogleAnalytics';
import VueMeta from 'vue-meta';
import { appInstance } from '@/infrastructure/config/appInstance';

Vue.config.productionTip = false;

setupVuex(Vue);
setupBus(Vue);

Vue.use(Autocomplete);
Vue.use(Button);
Vue.use(Checkbox);
Vue.use(Collapse);
Vue.use(Dropdown);
Vue.use(Field);
Vue.use(Icon);
Vue.use(Input);
Vue.use(Loading);
Vue.use(Menu);
Vue.use(Modal);
Vue.use(Pagination);
Vue.use(Select);
Vue.use(Switch);
Vue.use(Table);
Vue.use(Tag);
Vue.use(Tabs);
Vue.use(Taginput);
Vue.use(Tooltip);
Vue.use(Upload);
Vue.use(Progress);
Vue.use(Radio);

ConfigProgrammatic.setOptions({
    defaultStatusIcon: false
});

Vue.use(VueMeta);

const store = createStore();

initGoogleAnalytics(Vue);

Vue.use(VueReCaptcha, { siteKey: serverData.recaptchaSiteKey });

const app = new Vue({
    el: '#app',
    i18n,
    store,
    router,
    render: (h) => h(App)
});

appInstance.set(app);
export const $gtag = app.$gtag;
