import { Route, NavigationGuardNext } from 'vue-router';
import initStore from '@/store';
import UnAuthorizedError from '@/infrastructure/errors/UnAuthorizedError';

export default async (to: Route, from: Route, next: NavigationGuardNext) => {
    const isLoginRoute = to.name === 'login';
    const requiresAuth = to.matched.some((route) => route.meta.requiresAuth);
    const store = initStore();

    if (isLoginRoute) {
        try {
            await store.dispatch('auth/sessionDetails');

            // Logged, go to dashboard
            if (store.getters['auth/isUserLogged']) {
                next({ name: 'marketplace/sales', params: { ...to.params } });
                return;
            }
        } catch (error) {
            // Not logged, continue to login
            if (error instanceof UnAuthorizedError) {
                next();
            } else {
                throw error;
            }
            return;
        }
    }

    try {
        if (requiresAuth) {
            if (!store.getters['auth/isUserLogged'] || !store.getters['auth/hasMenuData']) {
                await store.dispatch('auth/sessionDetails');
            }
        }

        next();
    } catch (error) {
        if (error instanceof UnAuthorizedError) {
            await store.dispatch('auth/signOut', { showError: true });
        } else {
            throw error;
        }
    }
};
