import storage, { Storage } from '@/storage';
import { StorageKey } from '@/storage/keys';
import { ViewedTour } from './types';
import { TourData } from '@/infrastructure/plugins/shepherd';

export class MarkAsViewedTour {
    private storageKey: StorageKey;
    private storage: Storage;

    constructor(storage: Storage) {
        this.storage = storage;
        this.storageKey = StorageKey.VIEWED_TOURS;
    }

    public execute(tour: TourData): void {
        const viewedTours: ViewedTour[] | null = this.storage.get(this.storageKey);

        const viewedTour: ViewedTour = {
            name: tour.name,
            expirationDate: tour.expirationDate ? tour.expirationDate.toISOString() : null
        };

        if (viewedTours === null) {
            try {
                this.storage.set(this.storageKey, [viewedTour]);
            } catch {
                // We consciously decided to ignore this error
            }
            return;
        }

        const isAlreadyViewed = viewedTours.some((viewedTour) => viewedTour.name === tour.name);

        if (isAlreadyViewed) {
            return;
        }

        viewedTours.push(viewedTour);

        try {
            this.storage.set(this.storageKey, this.purgeOldData(viewedTours));
        } catch {
            // We consciously decided to ignore this error
        }
    }

    private purgeOldData(viewedTours: ViewedTour[]): ViewedTour[] {
        const now = new Date().toISOString();

        return viewedTours.filter((viewedTour) => {
            if (viewedTour.expirationDate === null) {
                return true;
            }

            if (Date.parse(viewedTour.expirationDate) >= Date.parse(now)) {
                return true;
            }

            return false;
        });
    }
}

export default () => new MarkAsViewedTour(storage);
