interface LocalStorage {
    getItem(key: string): string | null;
    setItem(key: string, value: string): void;
    removeItem(key: string): void;
}
export class Storage {
    private repository: LocalStorage;

    constructor(repository: LocalStorage) {
        this.repository = repository;
    }

    set(key: string, value: any) {
        try {
            this.repository.setItem(key, JSON.stringify(value));
        } catch {
            throw new Error('Exceeded Storage Quota!');
        }
    }

    get(key: string): any | null {
        const data = this.repository.getItem(key);

        try {
            return JSON.parse(data);
        } catch {
            return null;
        }
    }

    remove(key: string): void {
        try {
            this.repository.removeItem(key);
        } catch {
            throw new Error('Unable to remove it');
        }
    }
}

export default new Storage(localStorage);
