import { Route, NavigationGuardNext } from 'vue-router';
import { isPromofarma } from '@/infrastructure/config/businessUnit';
import store from '@/store';

const zendeskScriptIdValue = 'ze-snippet';

const injectZendeskWebWidgetScript = (): void => {
    let key = '';

    if (isPromofarma()) {
        key = '50f6ee0a-26d1-4620-9482-b21ce82362be';
    }

    const zendeskScript = document.createElement('script');
    zendeskScript.setAttribute('id', zendeskScriptIdValue);
    zendeskScript.setAttribute('src', `https://static.zdassets.com/ekr/snippet.js?key=${key}`);
    document.body.append(zendeskScript);
};

const setZendeskWebWidgetCookies = (isEnabled: boolean): void => {
    /*
     * Docs: https://developer.zendesk.com/api-reference/widget-messaging/introduction/
     */

    // @ts-expect-error
    if (typeof zE === 'function') {
        // @ts-expect-error
        zE('messenger:set', 'cookies', isEnabled);

        return;
    }

    // eslint-disable-next-line no-console
    console.error('The Zendesk Web Widget is not present on DOM');
};

export default async (to: Route, from: Route, next: NavigationGuardNext) => {
    if (!isPromofarma()) {
        next();

        return;
    }

    const isZendeskScriptLoaded: boolean = !!document.getElementById(zendeskScriptIdValue);
    const isUserLogged: boolean = store().getters['auth/isUserLogged'];

    if (isUserLogged) {
        if (!isZendeskScriptLoaded) {
            injectZendeskWebWidgetScript();
        } else {
            setZendeskWebWidgetCookies(true);
        }

        next();

        return;
    }

    if (isZendeskScriptLoaded) {
        setZendeskWebWidgetCookies(false);
    }

    next();
};
