import storage from '@/storage';
import { AppActions, AppContext, AppGetters, AppMutations, AppState, Sidebar } from '@/store/types';

const isSidebarOpen = (): boolean => {
    const sidebarStatus = storage.get('sidebarStatus');

    if (sidebarStatus === null) {
        storage.set('sidebarStatus', 1);

        return true;
    }

    return !!sidebarStatus;
};

const initialState: AppState = {
    sidebar: {
        opened: isSidebarOpen()
    }
};

export const actions: AppActions = {
    toggleSideBar({ commit, state }: AppContext): void {
        commit('TOGGLE_SIDEBAR');

        if (state.sidebar.opened) {
            storage.set('sidebarStatus', 1);
        } else {
            storage.set('sidebarStatus', 0);
        }
    },
    closeSideBar({ commit, state }: AppContext): void {
        if (state.sidebar.opened) {
            commit('CLOSE_SIDEBAR');
            storage.set('sidebarStatus', 0);
        }
    },
    openSideBar({ commit, state }: AppContext): void {
        if (!state.sidebar.opened) {
            commit('OPEN_SIDEBAR');
            storage.set('sidebarStatus', 1);
        }
    }
};

const mutations: AppMutations = {
    TOGGLE_SIDEBAR: (state: AppState): void => {
        state.sidebar.opened = !state.sidebar.opened;
    },
    CLOSE_SIDEBAR: (state: AppState): void => {
        state.sidebar.opened = false;
    },
    OPEN_SIDEBAR: (state: AppState): void => {
        state.sidebar.opened = true;
    }
};

export const getters: AppGetters = {
    sidebar: (state: AppState): Sidebar => state.sidebar
};

export default {
    namespaced: true,
    state: initialState,
    mutations,
    actions,
    getters
};
