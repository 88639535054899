import { BusinessUnit } from './businessUnit';
import { ServiceMode } from './serviceMode';

type INITIAL_DATA = {
    routesPrefix: string;
    serviceMode: ServiceMode;
    isProd: boolean;
    businessUnit: BusinessUnit;
    googleAnalyticsId: string | null;
    recaptchaSiteKey: string;
};

declare const window: Window &
    typeof globalThis & {
        __INITIAL_DATA__: any;
    };

export const serverData = ((): INITIAL_DATA => {
    try {
        return JSON.parse(window.__INITIAL_DATA__) as INITIAL_DATA;
    } catch (e) {
        /* eslint-disable-next-line no-console */
        console.error(
            'Something went wrong when trying to parse Initial data',
            e instanceof Error ? { message: e.message, stack: e.stack } : {}
        );
    }
})();
