import storage, { Storage } from '@/storage';
import { ConsentGA, StorageKey } from './types';

export class SetConsentGA {
    private storageKey: StorageKey.CONSENT_GA;
    private storage: Storage;

    constructor(storage: Storage) {
        this.storage = storage;
        this.storageKey = StorageKey.CONSENT_GA;
    }

    public execute(consent: ConsentGA): void {
        try {
            this.storage.set(this.storageKey, consent);
        } catch {
            throw new Error('An error occurred while trying to set the consent of GA');
        }
    }
}

export default () => new SetConsentGA(storage);
