import storage, { Storage } from '@/storage';
import { StorageKey } from '@/storage/keys';
import { ViewedTour } from './types';

export class GetViewedTours {
    private storageKey: StorageKey;
    private storage: Storage;

    constructor(storage: Storage) {
        this.storage = storage;
        this.storageKey = StorageKey.VIEWED_TOURS;
    }

    public execute(): ViewedTour[] {
        return this.storage.get(this.storageKey) || [];
    }
}

export default () => new GetViewedTours(storage);
