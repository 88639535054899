import { datadogRum } from '@datadog/browser-rum';
import { serverData } from '@/infrastructure/config/serverData';
import { getDatadogConfiguration } from '@/infrastructure/config/datadog/getDatadogConfiguration';

const datadogConfiguration = getDatadogConfiguration(serverData.businessUnit);

if (serverData.isProd) {
    datadogRum.init({
        applicationId: datadogConfiguration.applicationId,
        clientToken: datadogConfiguration.clientToken,
        site: 'datadoghq.eu',
        service: 'partner-home-web',
        datacenter: 'eu',
        sampleRate: 100,
        replaySampleRate: 0,
        defaultPrivacyLevel: 'mask-user-input'
    });
}

export default datadogRum;
