import { VueGtag } from 'vue-gtag';
import { TourName } from '@/services/analytics/tours/types';

export class TourEventLogger {
    private analyticsLogger: VueGtag;

    constructor(analyticsLogger: VueGtag) {
        this.analyticsLogger = analyticsLogger;
    }

    started(tourName: TourName): void {
        this.analyticsLogger.event('tutorial_begin', {
            tutorial_name: tourName
        });
    }

    completed(tourName: TourName): void {
        this.analyticsLogger.event('tutorial_complete', {
            tutorial_name: tourName
        });
    }

    skipped(tourName: TourName): void {
        this.analyticsLogger.event('tutorial_skip', {
            tutorial_name: tourName
        });
    }
}
