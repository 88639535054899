import axios, { AxiosError, AxiosResponse, AxiosRequestConfig } from 'axios';
import store from '@/store';
import UnAuthorizedError from '@/infrastructure/errors/UnAuthorizedError';
import BadRequestError from '@/infrastructure/errors/BadRequestError';
import { HttpStatus } from '@/infrastructure/http/HttpStatus';

export const config: AxiosRequestConfig = {
    baseURL: '/api'
};

const fetcher = axios.create(config);

type CustomErrorData = {
    message?: string;
    data?: any;
};

const isUnauthorizedError = (error: AxiosError<CustomErrorData>): boolean => error.response?.status === HttpStatus.UNAUTHORIZED;

const isBadRequestError = (error: AxiosError<CustomErrorData>): boolean => error.response?.status === HttpStatus.BAD_REQUEST;

const isAnErrorRelatedToLoginForm = (error: AxiosError<CustomErrorData>): boolean => {
    const errorMessagesRelatedToLoginForm: string[] = ['invalid_credentials', 'user_banned'];
    return errorMessagesRelatedToLoginForm.includes(error.response.data.message);
};

const isASessionDetailsError = (error: AxiosError<CustomErrorData>): boolean => {
    return error.response.request?.responseURL?.includes('/api/session-details');
};

export const onResponse = (response: AxiosResponse<unknown>): AxiosResponse<unknown> => {
    return response;
};

export const onResponseError = async (error: AxiosError<CustomErrorData>): Promise<AxiosError<CustomErrorData>> => {
    if (isUnauthorizedError(error)) {
        if (!isAnErrorRelatedToLoginForm(error) && !isASessionDetailsError(error)) {
            await store().dispatch('auth/signOut', { showError: true });
        }

        return Promise.reject(new UnAuthorizedError(error.response.data.message));
    }

    if (isBadRequestError(error)) {
        return Promise.reject(new BadRequestError(error.response.data.message));
    }

    return Promise.reject(error);
};

fetcher.interceptors.response.use(onResponse, onResponseError);

export default fetcher;
