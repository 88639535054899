import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { createI18n } from 'vue-i18n-composable';
import { LOCALE } from '@/infrastructure/locales';
import { getLanguageFromLocale } from '@/infrastructure/locales/getLanguageFromLocale';
import { getBusinessUnit } from '@/infrastructure/config/getBusinessUnit';
import { getDefaultLocaleByBU } from '@/infrastructure/locales/getDefaultLocaleByBU';

const defaultLocale: LOCALE = getDefaultLocaleByBU(getBusinessUnit());

const loadMessagesSync = (locale: LOCALE) => {
    return require(`@/infrastructure/locales/${locale}/spa.${locale}.json`);
};

const loadMessagesAsync = (locale: LOCALE) => {
    return import(/* webpackChunkName: "lang-[request]" */ `@/infrastructure/locales/${locale}/spa.${locale}.json`);
};

Vue.use(VueI18n);

export const i18n = createI18n({
    locale: defaultLocale,
    messages: { [defaultLocale]: loadMessagesSync(defaultLocale) }
});

const loadedLocales: LOCALE[] = [defaultLocale];

const setI18nLocale = (locale: LOCALE): void => {
    i18n.locale = locale;
};

export const loadLanguageAsync = async (locale: LOCALE): Promise<void> => {
    if (i18n.locale === locale) {
        return;
    }

    if (loadedLocales.includes(locale)) {
        setI18nLocale(locale);
        document.documentElement.lang = getLanguageFromLocale(locale);

        return;
    }

    const module = await loadMessagesAsync(locale);

    i18n.setLocaleMessage(locale, module.default);
    loadedLocales.push(locale);
    setI18nLocale(locale);
    document.documentElement.lang = getLanguageFromLocale(locale);
};
