import storage, { Storage } from '@/storage';
import { ConsentGA, StorageKey } from './types';

export class GetConsentGA {
    private storageKey: StorageKey.CONSENT_GA;
    private storage: Storage;

    constructor(storage: Storage) {
        this.storage = storage;
        this.storageKey = StorageKey.CONSENT_GA;
    }

    public execute(): ConsentGA | null {
        return this.storage.get(this.storageKey) || null;
    }
}

export default () => new GetConsentGA(storage);
