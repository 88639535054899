import { AxiosInstance } from 'axios';
import axiosInstance from '@/infrastructure/fetcher/axios';
import { MenuItem } from './types';

export class DynamicMenuService {
    private fetcher: AxiosInstance;

    constructor(fetcher: AxiosInstance) {
        this.fetcher = fetcher;
    }

    async execute(sellerId: string): Promise<MenuItem[]> {
        const result = await this.fetcher.get(`/dynamic-menu/${sellerId}`);

        return result.data;
    }
}

export default () => new DynamicMenuService(axiosInstance);
