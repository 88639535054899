import { LOCALE } from '@/infrastructure/locales';
import { BusinessUnit } from '@/infrastructure/config/businessUnit';

export const getDefaultLocaleByBU = (businessUnit: BusinessUnit): LOCALE => {
    const defaultLocalesByBU = {
        [BusinessUnit.DOCMORRIS]: LOCALE.de_DE,
        [BusinessUnit.ZURROSE]: LOCALE.de_CH,
        [BusinessUnit.PROMOFARMA]: LOCALE.en_GB
    };

    return defaultLocalesByBU[businessUnit];
};
