<!-- eslint-disable-next-line vue/valid-template-root -->
<template />

<script>
import { SnackbarProgrammatic as SnackBar } from 'buefy';
import UnAuthorizedError from '@/infrastructure/errors/UnAuthorizedError.ts';

export default {
    created() {
        this.$globalBus.on('error', this.showErrorNotification);
        this.$globalBus.on('success', this.showSuccessNotification);
        this.$globalBus.on('warning', this.showWarningNotification);
    },
    beforeUnmount() {
        this.$globalBus.off('error');
        this.$globalBus.off('success');
        this.$globalBus.off('warning');
    },
    methods: {
        showErrorNotification({ message, error } = {}) {
            if (!message) {
                message = 'error_occurred';
            }

            SnackBar.open({
                message: `
                    <i class="icon mdi mdi-alert-circle mdi-24px"></i>
                    <p data-qa-ta="errorSnackbarMessage">${this.$t(message)}</p>
                `,
                type: 'is-danger',
                actionText: null,
                duration: 6000,
                position: 'is-bottom-right',
                queue: false
            });

            if (error) {
                if (error instanceof UnAuthorizedError) {
                    return;
                }
                // eslint-disable-next-line no-console
                console.error(error); // Used to log the errors on DataDog
            }
        },
        showSuccessNotification(message) {
            SnackBar.open({
                message: `
                <i class="icon mdi mdi-check-circle mdi-24px"></i>
                <p data-qa-ta="successSnackbarMessage">${this.$t(message)}</p>
                `,
                type: 'is-success',
                actionText: null,
                duration: 3500,
                position: 'is-bottom-right',
                queue: false
            });
        },
        showWarningNotification(message) {
            SnackBar.open({
                message: `
                <i class="icon mdi mdi-alert mdi-24px"></i>
                <p data-qa-ta="warningSnackbarMessage">${this.$t(message)}</p>
                `,
                type: 'is-warning',
                actionText: null,
                duration: 3500,
                position: 'is-bottom-right',
                queue: false
            });
        }
    }
};
</script>
