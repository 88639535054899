import { Route, NavigationGuardNext } from 'vue-router';
import GetConsentGA from '@/services/shared/analytics/GetConsentGA';
import { ConsentGA } from '@/services/shared/analytics/types';
import { globalBus } from '@/infrastructure/plugins/globalBus';
import { isZurrose, isPromofarma } from '@/infrastructure/config/businessUnit';

export default async (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {
    if (isZurrose()) {
        next();

        return;
    }

    /**
     * Only for the promofarma business unit, and temporarily, we will ignore if the user gave consent and we will activate the analytics.
     * In the coming weeks, what is necessary to obtain consent will be implemented in that business unit.
     */
    if (isPromofarma()) {
        globalBus.emit('consent/enable-google-analytics');
        next();

        return;
    }

    const isPrivateRoute = to.meta?.requiresAuth === true;
    const consentGAStatus: ConsentGA | null = GetConsentGA().execute();

    if (consentGAStatus === ConsentGA.ALLOWED) {
        globalBus.emit('consent/enable-google-analytics');
        next();

        return;
    }

    if (consentGAStatus === ConsentGA.DENIED) {
        globalBus.emit('consent/disable-google-analytics');
        next();

        return;
    }

    globalBus.emit('consent/disable-google-analytics');

    if (!isPrivateRoute) {
        globalBus.emit('consent/ask-for-consent');
    }

    next();
};
