import { VueGtag } from 'vue-gtag';
import { AppliesTo } from '@/services/analytics/types';
import { FilterCriteria } from '@/services/analytics/filters/types';

export class FilterEventLogger {
    private analyticsLogger: VueGtag;

    constructor(analyticsLogger: VueGtag) {
        this.analyticsLogger = analyticsLogger;
    }

    filtered(appliesTo: AppliesTo, filterCriteria: FilterCriteria, filteredTerm: string): void {
        this.analyticsLogger.event('filter', {
            applies_to: appliesTo,
            criteria: filterCriteria,
            filter_term: filteredTerm
        });
    }
}
