import { VueGtag } from 'vue-gtag';
import { $gtag } from '@/main.js';
import { AppliesTo, AnalyticParamValueNotDefinedOrEmpty } from '@/services/analytics/types';
import { LoginMethod } from '@/services/analytics/auth/types';
import { AuthEventLogger } from '@/services/analytics/auth/AuthEventLogger';
import { TourName } from '@/services/analytics/tours/types';
import { TourEventLogger } from '@/services/analytics/tours/TourEventLogger';
import { RequestedFileCriteria, UploadFileCriteria } from '@/services/analytics/files/types';
import { FileEventLogger } from '@/services/analytics/files/FileEventLogger';
import { SearchEventLogger } from '@/services/analytics/searches/SearchEventLogger';
import { SearchCriteria } from '@/services/analytics/searches/types';
import { FilterEventLogger } from '@/services/analytics/filters/FilterEventLogger';
import { FilterCriteria } from '@/services/analytics/filters/types';
import { ItemEventLogger } from '@/services/analytics/items/ItemEventLogger';
import { LookItemListCriteria } from '@/services/analytics/items/types';

export class AnalyticsEventLogger {
    private readonly auth: AuthEventLogger;
    private readonly tour: TourEventLogger;
    private readonly file: FileEventLogger;
    private readonly search: SearchEventLogger;
    private readonly filter: FilterEventLogger;
    private readonly item: ItemEventLogger;

    constructor(analyticsLogger: VueGtag) {
        this.auth = new AuthEventLogger(analyticsLogger);
        this.tour = new TourEventLogger(analyticsLogger);
        this.file = new FileEventLogger(analyticsLogger);
        this.search = new SearchEventLogger(analyticsLogger);
        this.filter = new FilterEventLogger(analyticsLogger);
        this.item = new ItemEventLogger(analyticsLogger);
    }

    login(loginMethod: LoginMethod = LoginMethod.EMAIL_AND_PASSWORD): void {
        this.auth.login(loginMethod);
    }

    tourStarted(tourName: TourName): void {
        this.tour.started(tourName);
    }

    tourCompleted(tourName: TourName): void {
        this.tour.completed(tourName);
    }

    tourSkipped(tourName: TourName): void {
        this.tour.skipped(tourName);
    }

    fileRequested(appliesTo: AppliesTo, fileCriteria: RequestedFileCriteria): void {
        this.file.requested(appliesTo, fileCriteria);
    }

    fileUploaded(appliesTo: AppliesTo, fileCriteria: UploadFileCriteria): void {
        this.file.uploaded(appliesTo, fileCriteria);
    }

    searched(
        appliesTo: AppliesTo,
        searchCriteria: SearchCriteria | AnalyticParamValueNotDefinedOrEmpty,
        searchTerm: string | AnalyticParamValueNotDefinedOrEmpty
    ): void {
        this.search.searched(appliesTo, searchCriteria, searchTerm);
    }

    searchedResults(
        appliesTo: AppliesTo,
        searchCriteria: SearchCriteria | AnalyticParamValueNotDefinedOrEmpty,
        searchTerm: string | AnalyticParamValueNotDefinedOrEmpty,
        hasResults: boolean
    ): void {
        this.search.searchedResults(appliesTo, searchCriteria, searchTerm, hasResults);
    }

    filtered(appliesTo: AppliesTo, filterCriteria: FilterCriteria, filterTerm: string): void {
        this.filter.filtered(appliesTo, filterCriteria, filterTerm);
    }

    lookedItemList(appliesTo: AppliesTo, lookItemListCriteria: LookItemListCriteria): void {
        this.item.lookedItemList(appliesTo, lookItemListCriteria);
    }
}

export default () => new AnalyticsEventLogger($gtag);
