import { Route, NavigationGuardNext } from 'vue-router';
import { redirectTo } from '@/infrastructure/utils/redirectTo';
import { RoutesV1 } from '@/infrastructure/config/routeServicesV1Mappings';
import { isDocMorris } from '@/infrastructure/config/businessUnit';

export default async (to: Route, from: Route, next: NavigationGuardNext) => {
    const isRegistrationRoute = to.name === 'registration';

    if (isRegistrationRoute) {
        if (!isDocMorris()) {
            redirectTo(RoutesV1.REGISTER_FORM, () => {
                next();
            });

            return;
        }
    }

    next();
};
