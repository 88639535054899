var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ErrorBoundary", [
    _c("div", { staticClass: "App" }, [
      _c(
        "div",
        { staticClass: "App-wrap" },
        [
          _c("router-view"),
          _vm._v(" "),
          _c("SnackbarNotification"),
          _vm._v(" "),
          _c("CookiesConsentModal"),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }