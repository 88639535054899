import { VueGtag } from 'vue-gtag';
import { AppliesTo } from '@/services/analytics/types';
import { RequestedFileCriteria, UploadFileCriteria } from '@/services/analytics/files/types';

export class FileEventLogger {
    private analyticsLogger: VueGtag;

    constructor(analyticsLogger: VueGtag) {
        this.analyticsLogger = analyticsLogger;
    }

    requested(appliesTo: AppliesTo, fileCriteria: RequestedFileCriteria): void {
        this.analyticsLogger.event('file_requested', {
            applies_to: appliesTo,
            criteria: fileCriteria
        });
    }

    uploaded(appliesTo: AppliesTo, fileCriteria: UploadFileCriteria): void {
        this.analyticsLogger.event('file_upload', {
            applies_to: appliesTo,
            criteria: fileCriteria
        });
    }
}
