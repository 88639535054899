import { VueGtag } from 'vue-gtag';
import { AppliesTo } from '@/services/analytics/types';
import { LookItemListCriteria } from '@/services/analytics/items/types';

export class ItemEventLogger {
    private analyticsLogger: VueGtag;

    constructor(analyticsLogger: VueGtag) {
        this.analyticsLogger = analyticsLogger;
    }

    lookedItemList(appliesTo: AppliesTo, lookItemListCriteria: LookItemListCriteria): void {
        this.analyticsLogger.event('look_item_list', {
            applies_to: appliesTo,
            criteria: lookItemListCriteria
        });
    }
}
