import Shepherd from 'shepherd.js';

export const initTour = (tourOptions: Shepherd.Tour.TourOptions = {}): Shepherd.Tour => {
    const tour = new Shepherd.Tour({
        useModalOverlay: true,
        keyboardNavigation: true,
        exitOnEsc: false,
        defaultStepOptions: {
            scrollTo: true,
            modalOverlayOpeningPadding: 10,
            modalOverlayOpeningRadius: 5,
            classes: 'ph-tour-theme'
        },
        ...tourOptions
    });

    return tour;
};

export interface TourData {
    name: string;
    launchDate?: Date | null;
    expirationDate: Date | null;
}

export const addProgressIndicator = (tour: Shepherd.Tour): void => {
    const currentStepElement = tour.getCurrentStep().getElement();
    const footer = currentStepElement.querySelector('.shepherd-footer');
    const progressElement = document.createElement('span');
    progressElement.className = 'shepherd-progress';
    progressElement.innerText = `${tour.steps.indexOf(tour.getCurrentStep()) + 1}/${tour.steps.length}`;
    footer.insertBefore(progressElement, currentStepElement.querySelector('.shepherd-button:first-child'));
};
