<script>
export default {
    name: 'ErrorBoundary',
    errorCaptured(error) {
        this.$globalBus.emit('error', { message: 'error_occurred', error });

        // Stop error propagation
        return false;
    },
    render() {
        return this.$slots.default;
    }
};
</script>
