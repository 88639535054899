import axiosInstance from '@/infrastructure/fetcher/axios';
import { AxiosInstance } from 'axios';

export class SignIn {
    private fetcher: AxiosInstance;

    constructor(fetcher: AxiosInstance) {
        this.fetcher = fetcher;
    }

    async execute(signInInfo: { password: string; username: string; hasRememberMe: boolean }): Promise<void> {
        await this.fetcher.post('/signin', {
            user: { password: signInInfo.password, username: signInInfo.username },
            hasRememberMe: signInInfo.hasRememberMe
        });
    }
}

export default () => new SignIn(axiosInstance);
