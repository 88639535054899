import { BusinessUnit } from '@/infrastructure/config/businessUnit';

type DatadogConfiguration = {
    applicationId: string;
    clientToken: string;
};

export const getDatadogConfiguration = (businessUnit: BusinessUnit): DatadogConfiguration => {
    const defaultConfiguration: DatadogConfiguration = {
        applicationId: '',
        clientToken: ''
    };

    const configuration = {
        [BusinessUnit.DOCMORRIS]: {
            applicationId: '52e83762-3d93-4e4d-b443-83381c18bdef',
            clientToken: 'pub3ed128c5f1cb29e807cdc9e30e5f80cb'
        },
        [BusinessUnit.ZURROSE]: {
            applicationId: 'a80acb82-b120-490b-a099-fc53b752e0f2',
            clientToken: 'pub54eb8f7873c50c2bc83f14d73de776d4'
        },
        [BusinessUnit.PROMOFARMA]: {
            applicationId: '02e01cb5-0c88-42ba-a7a8-d1bcd02015d7',
            clientToken: 'pub820e1a0c448b025e465a26d349157442'
        }
    };

    return configuration[businessUnit] || defaultConfiguration;
};
